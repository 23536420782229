// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","button":"button_a9fe575","input":"input_ec4c594","primaryAuthButton":"primaryAuthButton_b5a3792","secondaryAuthButton":"secondaryAuthButton_a2ed415","errors":"errors_e87f3f4","authForm":"authForm_bf62d0e","header":"header_ed95439","subHeader":"subHeader_d1f637c","altLink":"altLink_cd0459b","successText":"successText_ffa9e90","textBody":"textBody_fb36060","inputGroup":"inputGroup_c2839ba","inputSection":"inputSection_bfe87d2","passwordInputContainer":"passwordInputContainer_c6f43d2","showHidePass":"showHidePass_e67ad6b","showHideGlyph":"showHideGlyph_a8f9071","loadingSpinner":"loadingSpinner_f085ce0","successIcon":"successIcon_b78d807","altLinks":"altLinks_e894845","poweredBy":"poweredBy_c884fd0"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"button\":\"button_a9fe575\",\"input\":\"input_ec4c594\",\"primaryAuthButton\":\"primaryAuthButton_b5a3792\",\"secondaryAuthButton\":\"secondaryAuthButton_a2ed415\",\"errors\":\"errors_e87f3f4\",\"authForm\":\"authForm_bf62d0e\",\"header\":\"header_ed95439\",\"subHeader\":\"subHeader_d1f637c\",\"altLink\":\"altLink_cd0459b\",\"successText\":\"successText_ffa9e90\",\"textBody\":\"textBody_fb36060\",\"inputGroup\":\"inputGroup_c2839ba\",\"inputSection\":\"inputSection_bfe87d2\",\"passwordInputContainer\":\"passwordInputContainer_c6f43d2\",\"showHidePass\":\"showHidePass_e67ad6b\",\"showHideGlyph\":\"showHideGlyph_a8f9071\",\"loadingSpinner\":\"loadingSpinner_f085ce0\",\"successIcon\":\"successIcon_b78d807\",\"altLinks\":\"altLinks_e894845\",\"poweredBy\":\"poweredBy_c884fd0\"}";
        // 1737867911560
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  