// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_e9303cb","type-body-2":"type-body-2_d3bf9a7","type-body-3":"type-body-3_f433cd6","type-body-4":"type-body-4_fbb72f2","type-section-title":"type-section-title_bef1295","type-notes":"type-notes_c17034e","type-item-title":"type-item-title_f3d15cb","type-item-subtitle":"type-item-subtitle_c8624c0","type-subtitle":"type-subtitle_f2b2120","type-title":"type-title_ee72bdb","user":"user_c569725","container":"container_aa15c4a","list":"list_c2e5b64","listItem":"listItem_c9f7596","timer":"timer_c5c14e7","offerContainer":"offerContainer_eeb23d0","offer":"offer_b221344","actions":"actions_a70620f","left":"left_e45fddb","heading":"heading_f9136e4","awaitingResponse":"awaitingResponse_b31d86a","created":"created_e942624","comment":"comment_a9ef75a","prefix":"prefix_f0a53e9","right":"right_f5fb37a","price":"price_a0fc6ec","strike":"strike_d89ff9e"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_e9303cb\",\"type-body-2\":\"type-body-2_d3bf9a7\",\"type-body-3\":\"type-body-3_f433cd6\",\"type-body-4\":\"type-body-4_fbb72f2\",\"type-section-title\":\"type-section-title_bef1295\",\"type-notes\":\"type-notes_c17034e\",\"type-item-title\":\"type-item-title_f3d15cb\",\"type-item-subtitle\":\"type-item-subtitle_c8624c0\",\"type-subtitle\":\"type-subtitle_f2b2120\",\"type-title\":\"type-title_ee72bdb\",\"user\":\"user_c569725\",\"container\":\"container_aa15c4a\",\"list\":\"list_c2e5b64\",\"listItem\":\"listItem_c9f7596\",\"timer\":\"timer_c5c14e7\",\"offerContainer\":\"offerContainer_eeb23d0\",\"offer\":\"offer_b221344\",\"actions\":\"actions_a70620f\",\"left\":\"left_e45fddb\",\"heading\":\"heading_f9136e4\",\"awaitingResponse\":\"awaitingResponse_b31d86a\",\"created\":\"created_e942624\",\"comment\":\"comment_a9ef75a\",\"prefix\":\"prefix_f0a53e9\",\"right\":\"right_f5fb37a\",\"price\":\"price_a0fc6ec\",\"strike\":\"strike_d89ff9e\"}";
        // 1737954364927
        var cssReload = require("../../../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  