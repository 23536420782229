// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","type-body-1":"type-body-1_ea7cbb4","type-body-2":"type-body-2_e4ff990","type-body-3":"type-body-3_d7683b0","type-body-4":"type-body-4_d4aed0c","type-section-title":"type-section-title_ac7fda3","type-notes":"type-notes_ad14a7d","type-item-title":"type-item-title_eea2307","type-item-subtitle":"type-item-subtitle_c9e7d8f","type-subtitle":"type-subtitle_fd1a67e","type-title":"type-title_df0798f","subtitle":"subtitle_f60e4a6","container":"container_f6fa4ec","title":"title_d5b00e3","infoBox":"infoBox_e032990"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"type-body-1\":\"type-body-1_ea7cbb4\",\"type-body-2\":\"type-body-2_e4ff990\",\"type-body-3\":\"type-body-3_d7683b0\",\"type-body-4\":\"type-body-4_d4aed0c\",\"type-section-title\":\"type-section-title_ac7fda3\",\"type-notes\":\"type-notes_ad14a7d\",\"type-item-title\":\"type-item-title_eea2307\",\"type-item-subtitle\":\"type-item-subtitle_c9e7d8f\",\"type-subtitle\":\"type-subtitle_fd1a67e\",\"type-title\":\"type-title_df0798f\",\"subtitle\":\"subtitle_f60e4a6\",\"container\":\"container_f6fa4ec\",\"title\":\"title_d5b00e3\",\"infoBox\":\"infoBox_e032990\"}";
        // 1737867929899
        var cssReload = require("../../../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  