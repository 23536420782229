// extracted by mini-css-extract-plugin
export default {"colorBlack":"#1d2126","colorBlackTransparent":"#1d212680","colorBlue":"#378ccb","colorBlueDark":"#0c65a7","colorBlueLighter":"#ebf4fa","colorGrayDark":"#5b656d","colorGrayDarker":"#313d48","colorGrayDarkest":"#24292f","colorGrayLight":"#adb2b6","colorGrayLighter":"#d6d8da","colorGrayLightest":"#ebeced","colorGreen":"#26b899","colorGreenLightest":"#e9f8f5","colorRed":"#e76f67","colorRedLightest":"#fdf1f0","colorText":"#24292f","colorWhite":"#fff","colorYellow":"#f6b551","fontWeightThin":"200","fontWeightRegular":"400","fontWeightSemiBold":"600","fontWeightBold":"700","fontSize10":".625rem","zIndexNeg1":"-1","zIndex0":"0","zIndex1":"1","zIndex2":"2","zIndex3":"3","zIndex4":"4","zIndex5":"5","sidePanel":"sidePanel_eab32e9","noButtons":"noButtons_c02bc84","container":"container_b1ff5a1","unreadMessagesNotVisibleTop":"unreadMessagesNotVisibleTop_b9ebfba","unreadMessagesNotVisibleBottom":"unreadMessagesNotVisibleBottom_bac2863","section":"section_b4074a0","sectionTitle":"sectionTitle_d8f760a","unreadMessagesButtonContainer":"unreadMessagesButtonContainer_aaf5601","down":"down_b7f162d","unreadMessagesButton":"unreadMessagesButton_d91b436","chevronGlyph":"chevronGlyph_ef1c399","refreshButton":"refreshButton_a083de3"};
    if(module.hot) {
      (function() {
        var localsJsonString = "{\"colorBlack\":\"#1d2126\",\"colorBlackTransparent\":\"#1d212680\",\"colorBlue\":\"#378ccb\",\"colorBlueDark\":\"#0c65a7\",\"colorBlueLighter\":\"#ebf4fa\",\"colorGrayDark\":\"#5b656d\",\"colorGrayDarker\":\"#313d48\",\"colorGrayDarkest\":\"#24292f\",\"colorGrayLight\":\"#adb2b6\",\"colorGrayLighter\":\"#d6d8da\",\"colorGrayLightest\":\"#ebeced\",\"colorGreen\":\"#26b899\",\"colorGreenLightest\":\"#e9f8f5\",\"colorRed\":\"#e76f67\",\"colorRedLightest\":\"#fdf1f0\",\"colorText\":\"#24292f\",\"colorWhite\":\"#fff\",\"colorYellow\":\"#f6b551\",\"fontWeightThin\":\"200\",\"fontWeightRegular\":\"400\",\"fontWeightSemiBold\":\"600\",\"fontWeightBold\":\"700\",\"fontSize10\":\".625rem\",\"zIndexNeg1\":\"-1\",\"zIndex0\":\"0\",\"zIndex1\":\"1\",\"zIndex2\":\"2\",\"zIndex3\":\"3\",\"zIndex4\":\"4\",\"zIndex5\":\"5\",\"sidePanel\":\"sidePanel_eab32e9\",\"noButtons\":\"noButtons_c02bc84\",\"container\":\"container_b1ff5a1\",\"unreadMessagesNotVisibleTop\":\"unreadMessagesNotVisibleTop_b9ebfba\",\"unreadMessagesNotVisibleBottom\":\"unreadMessagesNotVisibleBottom_bac2863\",\"section\":\"section_b4074a0\",\"sectionTitle\":\"sectionTitle_d8f760a\",\"unreadMessagesButtonContainer\":\"unreadMessagesButtonContainer_aaf5601\",\"down\":\"down_b7f162d\",\"unreadMessagesButton\":\"unreadMessagesButton_d91b436\",\"chevronGlyph\":\"chevronGlyph_ef1c399\",\"refreshButton\":\"refreshButton_a083de3\"}";
        // 1737954360154
        var cssReload = require("../../../../../node_modules/mini-css-extract-plugin/dist/hmr/hotModuleReplacement.js")(module.id, {});
        // only invalidate when locals change
        if (
          module.hot.data &&
          module.hot.data.value &&
          module.hot.data.value !== localsJsonString
        ) {
          module.hot.invalidate();
        } else {
          module.hot.accept();
        }
        module.hot.dispose(function(data) {
          data.value = localsJsonString;
          cssReload();
        });
      })();
    }
  